// @ts-nocheck
'use strict';
import $ from 'jquery';
import { getCookie } from './utils/get_cookie.js';


 $(document).on('ready', function() {
        $(".center").slick({
          slidesToShow: 4,
          slidesToScroll: 4,
          mobileFirst:true
        });

    updateSliderDimensions()
    $('.draggable').each(function() {
        $(this).width(windowWidth -44)
    });

    $('.slick-track').each(function() { //안그러면 margin auto가 되어 중앙정렬이 됨
        $(this).css('margin-left','inherit')
        $(this).css('margin-right','inherit')
    });

});
var slider = $('.center');

var windowWidth = window.innerWidth;
var w ;
var h;  //패딩등 고려하여 2:3으로 그림이 나오게 하려면..
calSize()

function calSize(){
    w = (windowWidth - 42)/4-10;
    h = w*2/3 + 20;  //패딩등 고려하여 2:3으로 그림이 나오게 하려면..
}

function updateSliderDimensions() {
        slider.slick('slickSetOption', {
            slidesToShow: 4,
            slidesToScroll: 4, //한번 스콜롤시 몇개 넘어가게
          mobileFirst:false,
          infinite: false,
          loop: false,
          centerMode: false,
            adaptiveHeight: true, // 세로 길이를 자동으로 조정합니다.
            responsive: [
                {
                    breakpoint: 768, // 크기 작아지면 아래 설정대로..
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                }
            ]
        }, true);

        // 슬라이드의 높이를 설정합니다.
        slider.find('.slick-slide').css('height', h + 'px');
        slider.find('.slick-slide').css('width', w + 'px');
    }

$(window).on('resize', function() {
    calSize()
    updateSliderDimensions()
});



function setUpUI() {


}


setUpUI();
